import { render, staticRenderFns } from "./environment.vue?vue&type=template&id=78df38e6&scoped=true"
import script from "./environment.vue?vue&type=script&lang=js"
export * from "./environment.vue?vue&type=script&lang=js"
import style0 from "./environment.vue?vue&type=style&index=0&id=78df38e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78df38e6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VMenu,VSubheader})
