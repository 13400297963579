var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.environment)?_c('CyHeader',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'project', params: { projectCanonical: _vm.projectCanonical } }}},[_vm._v(" "+_vm._s(_vm.projectName)+" ")]),_c('span',{staticClass:"separator",domProps:{"textContent":_vm._s('/')}}),_c('CyEnvironmentMenu',{attrs:{"pipelines":_vm.extractStartStopPipelines(_vm.pipelines).regular,"current-env":_vm.environment,"envs":_vm.envs},on:{"update:currentEnv":function($event){_vm.environment=$event},"update:current-env":function($event){_vm.environment=$event}}})]},proxy:true},{key:"actions",fn:function(){return [(!_vm._.$isEmpty(_vm.$static.menuItems))?_c('CyMenu',{staticClass:"cy-project-card-header__menu",attrs:{"offset-y":"","left":"","items":_vm.$static.menuItems},scopedSlots:_vm._u([{key:"subheader",fn:function(){return [_c('v-subheader',[_c('div',{staticClass:"v-subheader__title cy-headline"},[_vm._v(" "+_vm._s(_vm.$t('Environment'))+" ")]),_c('div',{staticClass:"v-subheader__environment"},[_c('CyAvatar',{attrs:{"item":_vm.environment,"sm":""}}),_c('span',{staticClass:"canonical"},[_vm._v(" "+_vm._s(_vm.environment.canonical)+" ")])],1)])]},proxy:true},{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('CyButton',_vm._g(_vm._b({attrs:{"icon":"more_horiz","theme":"primary","variant":"secondary"}},'CyButton',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('more'))+" ")])]}}],null,false,435402430)}):_vm._e()]},proxy:true},{key:"metadata",fn:function(){return [(_vm.stackCanonical)?_c('v-menu',{attrs:{"open-on-hover":"","open-delay":"200","close-delay":"200","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CyHeaderMetadata',_vm._g({attrs:{"icon":"fa-cubes","to":{ name: 'stack', params: { stackRef: _vm.stackRef } },"label":_vm.stackCanonical}},on))]}}],null,false,2623844334)},[_c('CyWizardServiceCard',{staticClass:"border-none",attrs:{"hide-more-btn":"","max-width":440,"data-cy":"stack-card-summary","service":_vm.project.service_catalog}})],1):_vm._e(),(_vm.environment.use_case)?_c('CyTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CyHeaderMetadata',_vm._g({attrs:{"icon":"fa-cube","label":_vm.environment.use_case}},on))]}}],null,false,1163157985)},[_vm._v(" "+_vm._s(_vm.$t('environment.useCase'))+" ")]):_vm._e(),(_vm.environment.cloud_provider_canonical)?_c('CyHeaderMetadata',[_c('CyIconCredential',{staticClass:"mr-0",attrs:{"size":"20","type":_vm.environment.cloud_provider_canonical}})],1):_vm._e(),(_vm.environment.created_at)?_c('CyHeaderMetadata',{attrs:{"label":_vm.getLabel('created')}}):_vm._e(),(_vm.environment.updated_at)?_c('CyHeaderMetadata',{attrs:{"label":_vm.getLabel('updated')}}):_vm._e()]},proxy:true}],null,false,327993457)}):_vm._e(),(_vm.modal)?_c('CyFormsEnvironmentDelete',{attrs:{"environment":_vm.environment,"project-canonical":_vm.projectCanonical},on:{"cancel":function($event){return _vm.$toggle.modal()},"success":_vm.onDeleteSuccess}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }